export const PROGRAMS = [
    {
        name: "Harness 1440",
        description: "Struggling to manage your growth? Increase your productivity and skills by managing your time effectively. Book your slot now!",
        subText: "Book the slot >",
        subLink: "https://www.fiverr.com/vinodpthmn/mentor-you-to-increase-productivity-through-efficient-time-management"
    },
    {
        name: "Aspiring Architects",
        description: "Are you an engineer with 6-8 years of experience? Pivot your career now to elevate yourself as an Architect. Subscribe now for a career shift!",
        subText: "Subscribe now >",
        subLink: "https://docs.google.com/forms/d/e/1FAIpQLSchWg4UyJ2qB1EA9ZcAwgPjymI4ZrQKAO2akDg1sRhQp-AHTg/viewform"
    },
    {
        name: "Startup Appetite",
        description: "Experiencing inner calling to \"Startup\" something? Boost your confidence with the right technology and architecture guidance. Connect with me!",
        subText: "Connect >",
        subLink: "https://docs.google.com/forms/d/e/1FAIpQLSfYHBgw0rAlQUJ-B8StsYP-ySi9zgcQB7QN6EP6-YJagQ1Ucg/viewform?usp=sf_link"
    }
    ];
