function Profile() {
  return (
      <div className="footer-profile-container">
        <div className="footer-profile-photo"/>
        
        <div className="footer-profile-info">
          A passionate technologist, who believes in continuous learning. Being in the industry for the past 25+ years, I got an opportunity to work with various product companies. I was lucky to start working in an era where there were no process/management overheads,  it helped me to explore all the areas of software engineering. Right from requirement gathering, journey mapping, design, development, testing, deployment to customer engagement. 
          <p >
          In the course of this phenomenal journey, my mentors played a crucial role in shaping my career. In fact, the interactions with them helped me to learn, grow and succeed. It is always hard to get the right guidance at the right time. Through this site my intentions are to mentor a few and impart what I have learned from my mentors.
          </p>
        </div>
      </div>
  );
}

export default Profile;
