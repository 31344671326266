
function Copyright() {
  return (
      <div className="footer-copyright">
      © 2023 Vinod Purushothaman
      </div>
  );
}

export default Copyright;
