import { CONSULTINGS } from "../data/ConsultingList";
import ConsultingItem from "./ConsultingItem";

function Consulting() {
  return (
    <div>
      <div className="consulting-title">
        Consulting
      </div>
      <div className='consulting-container'>
        {
          CONSULTINGS.map((consult, idx) => 
            <ConsultingItem data={consult} key={idx}/>          
          )
        }
      </div>
    </div>
  );
}

export default Consulting;
