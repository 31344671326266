import { getAnalytics, logEvent } from "firebase/analytics";

function Header() {

  const gaEvent = (medium) => {
    const analytics = getAnalytics();
    logEvent(analytics, 'connect_' + medium);
  }

  return (
      <div className='header-container'>
        {/* <div className="logo"/> */}

        <div className="title-container">
          <div className='title-fullname'>
            Vinod Purushothaman
          </div>
          <div className='title-traits'>
          Technologist&nbsp;.&nbsp;Mentor&nbsp;.&nbsp;Agilist
          </div>
        </div>

        <div className="connect-container">
          <div className="connect-item" onClick={() => gaEvent('channel')}>
            <a href="https://www.youtube.com/@delta-learn">
              <img src={'/images/youtube.svg'} alt="Channel @Youtube"></img>
            </a>
          </div>
          <div className="connect-item" onClick={() => gaEvent('linkedin')}>
            <a href="https://www.linkedin.com/in/vinodpurushothaman">
              <img src={'/images/linkedin.svg'} alt="Follow @LinkedIn" ></img>
            </a>
          </div>
          <div className="connect-item" onClick={() => gaEvent('twitter')}>
            <a href="https://twitter.com/vinodpthmn">
              <img src={'/images/twitter.svg'} alt="Follow @twitter"></img>
            </a>
          </div>
          <div className="connect-item" onClick={() => gaEvent('mail')}>
            <a href="mailto:vinodpthmn@gmail.com?subject=Let us connect #website-ref&body=<Please give a brief intro about yourself, some context and contact details>">
              <img src={'/images/mail.svg'} alt="Contact me"></img>
            </a>
          </div>
        </div>
      </div>
  );

}

export default Header;
