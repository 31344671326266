import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Programs from './components/Programs';
import Banner from './components/Banner';
import Consulting from './components/Consulting';

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

import { firebaseConfig } from "./configs/FirebaseConfig"

function App() {

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  return (
    <div className="app">
      <Header/>
      <Banner/>
      <Programs/>
      <Consulting/>
      <Footer/>
    </div>
  );
}

export default App;
