import Copyright from "./Copyright";
import Profile from "./Profile";

function Footer() {
  return (
      <div className='footer-container'>
        <Profile/>
        <Copyright/>
      </div>
  );
}

export default Footer;
