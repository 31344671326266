function ConsultingItem({data}) {
  return (
        <div className="consulting-item">
          <div className="consulting-area">
            {data.area}
          </div>
          <div className="consulting-desc">
          {data.description}
          </div>
        </div>
  );
}

export default ConsultingItem;
