import { getAnalytics, logEvent } from "firebase/analytics";

function ProgramCard({data}) {

  const subscribe = (program) => {
    program = program.toLowerCase().replace(/\s/g, '_');
    const analytics = getAnalytics();
    logEvent(analytics, 'subscribe_' + program);
  }

  return (
        <div className="program-card">
          <div className="program-name">
            {data.name}
          </div>
          <div className="program-desc">
          {data.description}
          </div>
          <div className="program-sub" onClick={() => subscribe(data.name)}>
            <a className="program-sub-link" href={data.subLink}>{data.subText}</a> 
          </div>
        </div>
  );
}

export default ProgramCard;
