function Banner() {
    return (
        <div className='banner-container'>
            <div className="banner-quote">
                "One's success is boundless through efforts &nbsp;||&nbsp; முயற்சியால் ஒருவரின் வெற்றி எல்லையற்றது &nbsp;||&nbsp; പരിശ്രമത്തിലൂടെ ഒരാളുടെ വിജയത്തിന് അതിരുകളില്ല."
            </div>
        </div>
    );
  }
  
  export default Banner;
  