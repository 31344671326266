import {PROGRAMS} from "../data/ProgramList";
import ProgramCard from "./ProgramCard";

function Programs() {
  return (
    <div>
      <div className="program-title">
        Mentoring Programs
      </div>
      <div className='program-container'>
        {
          PROGRAMS.map((program, idx) => 
            <ProgramCard data={program} key={idx}/>
          )
        }
      </div>
    </div>
  );
}

export default Programs;
